<template>
	<div>
		<div>
			<div @click="setTheme(1)">
				111
			</div>
			<div @click="setTheme(2)">
				222
			</div>
			<div @click="setTheme(3)">
				333
			</div>
		</div>
		<div class="base-bg-color">
			dqwdsaw
		</div>
		<el-button class="block-btn" @click="showDialog">弹出层</el-button>
		<Wtable :demo="demo"></Wtable>
		<Wdialog ref="wDialog" :title="dialogTitle" :width="dialogWidth" @wConfirm="onConfirm"></Wdialog>
	</div>
</template>
<script>
	import Wtable from '@/components/wTable/wTable.vue';
	import Wdialog from '@/components/wDialog/wDialog.vue'
	import {
		ref
	} from 'vue'
	import {
		useRouter
	} from 'vue-router'
	export default {
		components: {
			Wtable,
			Wdialog
		},
		setup() {
			const router = useRouter();
			const setTheme = () => {
				router.push({
					name: 'operations'
				})
			}
			const demo = ref('dededede')
			const wDialog = ref(null);
			const showDialog = () => {
				wDialog.value.show()
			}
			const dialogTitle = ref('cesce')
			const  dialogWidth = ref('50%')
			/**
			 * 确认
			 * */
			const onConfirm =(e) =>{
				console.log(e);
			}
			return {
				dialogTitle,
				onConfirm,
				dialogWidth,
				wDialog,
				showDialog,
				setTheme,
				demo
			}
		}
	}
</script>
<style lang="scss" scoped>
.block-btn{
	background:  linear-gradient(to right, var(--theme-linear-color-left), var(--theme-linear-color-right));;
}
</style>
